import * as React from "react"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
  <div class="container">
    <div class="intro_bg"></div>
    <div class="page_title">
      <h1>Memecoin Tinuman</h1>
    </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="news">
          <iframe title="tinyman swap widget" class="widget" src="https://hipo.github.io/ui098gh4350u9h435y-swap-widget/?platformName=&network=mainnet&themeVariables=eyJ0aGVtZSI6ImxpZ2h0IiwiY29udGFpbmVyQnV0dG9uQmciOiIjRjJGRTY3Iiwid2lkZ2V0QmciOiIjMEQ5RjYyIiwiaGVhZGVyQnV0dG9uQmciOiIjMEI4NzUzIiwiaGVhZGVyQnV0dG9uVGV4dCI6IiMwMDAwMDAiLCJoZWFkZXJUaXRsZSI6IiMwMDAwMDAiLCJjb250YWluZXJCdXR0b25UZXh0IjoiIzAwMDAwMCIsImlmcmFtZUJnIjoiI0Y4RjhGOCIsImJvcmRlclJhZGl1c1NpemUiOiJtZWRpdW0iLCJ0aXRsZSI6IlN3YXAiLCJzaG91bGREaXNwbGF5VGlueW1hbkxvZ28iOmZhbHNlfQ%3D%3D&assetIn=0&assetOut=2217970766" 
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />
      </div>
      <div class="intro_description">
        <p>Tinuman is a vibrant and playful memecoin that has found its home on the Algorand blockchain. Designed to be both entertaining and innovative, Tinuman leverages Algorand’s high-speed, low-cost transactions and secure infrastructure to deliver a unique digital experience to its community.</p>
      </div>
      <div class="section helper">
        <a class="helper_box" href="https://app.tinyman.org/swap?asset_in=0&asset_out=2217970766" target="_blank" rel="noreferrer">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106488)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4.5L12 0.5V3.5H1V5.5H12V8.5L16 4.5ZM4 8.5L0 12.5L4 16.5V13.5H15V11.5H4V8.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106488">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Swap on Tinyman
          </h5>
          <p>Trade Algorand Standard Assets easily on Tinyman</p>
        </a>
        <a class="helper_box" href="https://app.tinyman.org/#/pool" target="_blank" rel="noreferrer">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106503)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8335 2.5H12.8335C13.6619 2.5 14.3335 3.17157 14.3335 4C14.3335 4.82843 13.6619 5.5 12.8335 5.5H3.8335C3.00507 5.5 2.3335 4.82843 2.3335 4C2.3335 3.17157 3.00507 2.5 3.8335 2.5ZM0.333496 4C0.333496 2.067 1.9005 0.5 3.8335 0.5H12.8335C14.7665 0.5 16.3335 2.067 16.3335 4C16.3335 5.933 14.7665 7.5 12.8335 7.5H3.8335C1.9005 7.5 0.333496 5.933 0.333496 4ZM3.8335 11.5H12.8335C13.6619 11.5 14.3335 12.1716 14.3335 13C14.3335 13.8284 13.6619 14.5 12.8335 14.5H3.8335C3.00507 14.5 2.3335 13.8284 2.3335 13C2.3335 12.1716 3.00507 11.5 3.8335 11.5ZM0.333496 13C0.333496 11.067 1.9005 9.5 3.8335 9.5H12.8335C14.7665 9.5 16.3335 11.067 16.3335 13C16.3335 14.933 14.7665 16.5 12.8335 16.5H3.8335C1.9005 16.5 0.333496 14.933 0.333496 13Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106503">
            <rect width="16" height="16" fill="white" transform="translate(0.333496 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Earn via Tinyman pools
          </h5>
          <p>Provide liquidity to Tinyman pools and earn fees on swaps.</p>
        </a> 
        <Link className="helper_box" to="/">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106514)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1665 0.5C3.50965 0.5 2.1665 1.84315 2.1665 3.5V13.5C2.1665 15.1569 3.50965 16.5 5.1665 16.5H14.1665H15.1665V14.5H14.6665V12.5H15.1665V10.5V2.5V1.5V0.5H5.1665ZM13.1665 2.5H5.1665C4.61422 2.5 4.1665 2.94772 4.1665 3.5V10.6707C4.47928 10.5602 4.81587 10.5 5.1665 10.5H13.1665V2.5ZM5.1665 12.5H12.6665V14.5H5.1665C4.61422 14.5 4.1665 14.0523 4.1665 13.5C4.1665 12.9477 4.61422 12.5 5.1665 12.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106514">
            <rect width="16" height="16" fill="white" transform="translate(0.666504 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Learn more about Tinuman
          </h5>
          <p>Learn about the core concepts of Tinuman and Algorand ecosystem</p>
        </Link> 
      </div>

      <div class="section-s newversion">
          <h2>Tinuman <br />designed for Community</h2>
          <p >Tinuman aspires to be more than just another memecoin; it aims to create a thriving community where humor and digital innovation intersect. By harnessing the power of Algorand’s blockchain, Tinuman is positioned to offer a seamless, entertaining, and engaging experience for users. </p>
          <a href="https://app.tinyman.org/swap?asset_in=0&asset_out=2217970766" class="mainnet_btn" target="_blank" rel="noreferrer">BUY NOW</a>
      </div>
      <div class="section helper migration">
      </div>

      <div class="section-s last">
        <h3>Our Roadmap</h3>
        <div class="roadmap_progress"></div>
        <ul class="list">
          <li>
            <h5>Q3 2024</h5>
            <p>Development Smart Contract</p>
            <p>$TINU Launch</p>
            <p>Locked 100% Liquidity</p>
            <p>Airdrop Campaigns</p>
            
          </li>
          <li>
            <h5>Q4 2024</h5>
            <p>Burning 10% Total Supply</p>
            <p>Farming Programs & Staking</p>
          </li>
          <li>
            <h5>Q1 2025</h5>
            <p>Charity</p>
            <p>Marketing Engagement</p>
            <p>Add Liquidity Pool</p>
          </li>
          <li>
            <h5>Q2 2025</h5>
            <p>Website Update</p>
            <p>Governance Programs</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IndexPage
